import React, { useState } from 'react';

import './AboutMe.css';
import us_in_amsterdam from '../assets/img/us_4.JPG';
import us_in_london1 from '../assets/img/us_1.jpeg';
import us_in_london2 from '../assets/img/us_2.jpeg';
import us_in_berlin from '../assets/img/us_5.jpeg';
import us_in_ams from '../assets/img/us_6.jpeg';
import us_in_ams_2 from '../assets/img/us_7.JPG';
import us_in_hague from '../assets/img/us_8.jpg';
import us_in_berlin_2 from '../assets/img/us_9.jpg';
import us_in_airport from '../assets/img/us_10.jpg';
import us_in_london from '../assets/img/us_11.jpg';
import ams_1 from '../assets/img/ams_1.jpeg';
import ams_2 from '../assets/img/ams_2.jpeg';
import ams_3 from '../assets/img/ams_3.jpeg';
import ams_4 from '../assets/img/ams_4.jpeg';

import ImageModal from './Modal';
import Carousel from './Carousel';
import ExpandIcon from './ExpandIcon';

export default function AboutMe() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(ams_1);

  const amsImages = [ams_1, ams_3, ams_4, ams_2];
  const usImages = [
    us_in_amsterdam,
    us_in_london1,
    us_in_london2,
    us_in_berlin,
    us_in_ams,
    us_in_ams_2,
    us_in_hague,
    us_in_london,
    us_in_berlin_2,
    us_in_airport,
  ];

  const openModal = image => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  return (
    <div className='app__about_me'>
      <ImageModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        src={selectedImage}
      />
      <div className='app__about_me-container'>
        <div className='app__about_me-summary'>
          <p>
            After visiting Amsterdam many times, my wife and I decided to make
            this place our home. The beautiful canals, stunning architecture,
            and amazing community vibe won us over. You can easily get pretty
            much anywhere within the city limits in under half an hour. There
            are even more bikes than people! Between the trains, trams, buses,
            ferries, and metro, it’s easy to get wherever you need to go. Every
            month, we make it a tradition to hop on a train and explore a new
            place in the Netherlands. Whether it's The Hague, Rotterdam,
            Utrecht, or the charming small villages nearby, each trip adds to
            our sense of adventure and enriches our lives. Amsterdam is the kind
            of place where you leave your doors open, your neighbors are your
            friends, and the sense of community is strong and welcoming. The mix
            of shops, cafes, and restaurants from all over the world makes you
            feel right at home. During holidays, our neighbors go all out with
            decorations, making the streets and gardens feel festive and
            magical. You get the vibe of being in a Christmas movie. It's a
            great feeling to be part of such a lively and warm community.
          </p>
          <div className='app__about_me-amsterdam-gallery'>
            {amsImages.map((image, index) => (
              <div
                key={index}
                className={`image-container ${index === 3 ? 'landscape' : ''}`}
                onClick={() => openModal(image)}
              >
                <img src={image} alt={`Amsterdam ${index + 1}`} />
                <ExpandIcon />
              </div>
            ))}
          </div>
          <p>
            Outside of enjoying the city, I have a big passion for fitness. I've
            been into powerlifting for a few years, reaching 120kg on the bench,
            200kg on the squat, and 220kg on the deadlift. Now, after several
            years of training, my focus has shifted toward health and wellness,
            with a greater emphasis on nutrition. I'm very fortunate that my
            wife shares the same passion for both fitness and nutrition. We've
            been working out together since we met, and she’s an amazing cook,
            making it easier not only to maintain a healthy diet, but to fully
            enjoy it.
          </p>
          <br />

          <p>
            I also have geekier hobbies involving Raspberry Pi projects and
            reading software engineering tech blogs. My Raspberry Pi serves as a
            small server in my home network for various projects. The latest one
            was a web crawler that searched for job ads on startup career pages
            that aren't listed on major job platforms.
          </p>
          <br />
          <p>
            Above all my hobbies and life goals, I value the time spent with my
            wife the most. I truly believe that time is our most valuable asset,
            and for me, maximizing each moment together is paramount. I feel
            incredibly lucky to have her in my life, and I know for a fact that
            I’ve found my lobster.
          </p>
          <Carousel images={usImages} />
        </div>
      </div>
    </div>
  );
}
