import {
  GITHUB_PROFILE_URL,
  LEETCODE_URL,
  LINKEDIN_PROFILE_URL,
  PERSONAL_EMAIL,
} from '../utils/constants';
import { FiMail } from 'react-icons/fi';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiLeetcode } from 'react-icons/si';

import React from 'react';
import './Socials.css';

export default function Socials() {
  return (
    <div className='app__social'>
      <div className='app__social-links'>
        <div className='app__social-links-email'>
          <a href={`mailto:${PERSONAL_EMAIL}`}>
            <FiMail className='app__social-links-email-logo' />
          </a>
        </div>

        <div className='app__social-links-linkedin'>
          <a href={LINKEDIN_PROFILE_URL} target='_blank' rel='noreferrer'>
            <FaLinkedin className='app__social-links-linkedin-logo' />
          </a>
        </div>

        <div className='app__social-links-leetcode'>
          <a href={LEETCODE_URL} target='_blank' rel='noreferrer'>
            <SiLeetcode className='app__social-links-leetcode-logo' />
          </a>
        </div>

        <div className='app__social-links-github'>
          <a href={GITHUB_PROFILE_URL} target='_blank' rel='noreferrer'>
            <FaGithub className='app__social-links-github-logo' />
          </a>
        </div>
      </div>
    </div>
  );
}
