import { NavLink } from 'react-router-dom';
import React from 'react';
import './Navbar.css';

export default function Navbar() {
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <NavLink to='/' className='app__navbar-logo-name'>
          Bogdan
          <span className='app__navbar-logo-title'>
            {' '}
            / Senior Software Engineer
          </span>
        </NavLink>
      </div>

      <ul className='app__navbar-links'>
        <li>
          {/*<NavLink to="/about" className="app__flex p-text" activeClassName="active-link"></NavLink>*/}
          <NavLink
            to='/about'
            className={({ isActive }) => {
              return isActive
                ? 'app__navbar-links-active'
                : 'app__navbar-links-about_me';
            }}
          >
            About Me
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
