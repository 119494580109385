import React from 'react';
import './Home.css';
import './Button.css';
import bogdan from '../assets/img/bogdan.jpeg';
import resume from '../assets/Bogdan_Copocean_Senior_Software_Engineer_Resume.pdf';
import Socials from './Socials';
import { AWS_SAA_CREDLY, HOTJAR_WEBSITE } from '../utils/constants';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className='app__home'>
      <div className='app__home-about_me'>
        <div className='app__home-about_me-card'>
          <div className='app__home-about_me-card-photo'>
            <img src={bogdan} alt='bogdan' />
          </div>

          <div className='app__home-about_me-card-name'>
            <p>Bogdan Copocean</p>
          </div>

          <div className='app__home-about_me-card-title'>
            <p>
              Senior Software Engineer
              <a href={HOTJAR_WEBSITE} target='_blank' rel='noreferrer'>
                {' '}
                @Hotjar
              </a>
            </p>
          </div>

          <div className='app__home-about_me-card-certification'>
            <p>
              AWS Solutions Architect Associate |
              <a href={AWS_SAA_CREDLY} target='_blank' rel='noreferrer'>
                {' '}
                Credly
              </a>
            </p>
          </div>

          <div className='app__home-about_me-card-links'>
            <div className='app__home-about_me-summary-download_resume'>
              <a
                href={resume}
                className='app__utils-full_button'
                rel='noreferrer'
                target='_blank'
              >
                Resume
              </a>
            </div>

            <div className='app__home-about_me-summary-about_me'>
              <Link to='/about' className='app__utils-outlined_button'>
                About Me
              </Link>
            </div>
          </div>

          <div className='app__home-about_me-card-socials'>
            <Socials />
          </div>
        </div>
      </div>
    </div>
  );
}
