import './Modal.css'; // Ensure the CSS styles are correctly defined

export default function ImageModal({ isOpen, onRequestClose, src }) {
  if (!isOpen) return null;

  return (
    <div className='modal-overlay' onClick={onRequestClose}>
      <div className='modal-content' onClick={e => e.stopPropagation()}>
        <img src={src} alt='Enlarged view' />
        <button className='close' onClick={onRequestClose}></button>
      </div>
    </div>
  );
}
