import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import './ExpandIcon.css';

const ExpandIcon = ({ onClick }) => {
  return (
    <FontAwesomeIcon
      icon={faExpand}
      className='expand-icon'
      onClick={onClick}
    />
  );
};

export default ExpandIcon;
