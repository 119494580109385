import React, { useState } from 'react';
import './Carousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSwipeable } from 'react-swipeable';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ImageModal from './Modal';
import ExpandIcon from './ExpandIcon';

const Carousel = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const prevPhotoIdx =
      currentIndex === 0 ? images.length - 1 : currentIndex - 1;

    setCurrentIndex(prevPhotoIdx);
    setSelectedImage(images[prevPhotoIdx]);
  };

  const goToNext = () => {
    const nextPhotoIdx =
      currentIndex === images.length - 1 ? 0 : currentIndex + 1;

    setCurrentIndex(nextPhotoIdx);
    setSelectedImage(images[nextPhotoIdx]);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} className='carousel-container'>
      <div className='carousel-wrapper'>
        <ImageModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          src={images[currentIndex]}
        />

        <button onClick={goToPrevious} className='left-arrow'>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <div className='carousel-content-wrapper'>
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-image-container ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setModalIsOpen(true)}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                className='carousel-image'
              />
              <ExpandIcon />
            </div>
          ))}
        </div>

        <button onClick={goToNext} className='right-arrow'>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <div className='carousel-dots'>
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
